<template>
  <div class="text-left">
    <div
      class="card border-radius"
      v-if="!getIsAccountDataLoading"
      style="border-color: var(--border-color); overflow: hidden"
    >
      <div class="d-flex align-items-center gap plan-card-header p-3">
        <div class="">
          <svg
            width="32"
            height="32"
            viewBox="0 0 32 32"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <rect width="32" height="32" rx="16" fill="#E5DEED" />
            <path
              d="M9.33337 17.6667L16 21L22.6667 17.6667M16 11L9.33337 14.3333L16 17.6667L22.6667 14.3333L16 11Z"
              stroke="#4D1B7E"
              stroke-width="1.33333"
              stroke-linecap="round"
              stroke-linejoin="round"
            />
          </svg>
        </div>
        <div class="">{{ plan.name }}</div>
      </div>
      <div class="p-3">
        <div class="d-flex justify-content-between">
          <div class="d-flex align-items-end gap">
            <div class="price-text">$ {{  parseInt(plan.price) }}</div>
            <div
              class="v16-section-description"
              style="
                color: var(--grey-85);
                line-height: 35px;
                text-transform: lowercase;
              "
            >
           per {{plan.duration}} {{ plan.duration == 'YEAR' ? 'paid yearly' : '' }}
            </div>
          </div>
          <div class=""></div>
        </div>
        <div
          class="v16-section-description"
          style="color: var(--grey-85); line-height: 35px"
        >
          Includes {{ formattedLimit.Engagements?.planValue }} engagement,
          {{ formattedLimit.Quizzes?.planValue }}
          {{ formattedLimit.Quizzes?.planValue > 1 ? "quizzes" : "quiz" }}, up
          to {{ formattedLimit.Questions?.planValue == 0 ? 'unlimited' : formattedLimit.Questions?.planValue }}
          {{
           "questions" 
          }}
          and {{ formattedLimit.Emails?.planValue }} emails, also integrations
          and jump logic with full analytics.
        </div>
        <div class="d-flex gap">
          <div
            class=""
            v-if="
              Object.entries(plan).length != 0 &&
              Object.entries(InvoiceData).length != 0
            "
          >
            <UpdatePaddlePaymentModal
              v-if="
                plan?.name.toLowerCase() != 'free' && InvoiceData.update_url
              "
              :PaddleUpdateURL="InvoiceData.update_url"
            ></UpdatePaddlePaymentModal>
          </div>
          <div class="">
            <Button @click="openUpgradePlan">Upgrade Plan</Button>
          </div>
        </div>
      </div>
    </div>
    <InvoiceOrderHistory />
  </div>
</template>
<script>
import UpdatePaddlePaymentModal from "./components/UpdatePaddlePaymentModal.vue";
import { mapGetters } from "vuex";
import axios from "axios";
import globalMixin from "../../../GlobalMixins/getSignedURLMixin";
import InvoiceOrderHistory from "./components/InvoiceOrderHistory.vue";
import Button from "../Layout/Button.vue";
export default {
  components: {
    // Heading,
    UpdatePaddlePaymentModal,
    Button,
    InvoiceOrderHistory,
  },

  mixins: [globalMixin],

  data() {
    return {
      // user: {},
      // Limitations: [],
      // plan: {},
      isAccountLoading: false,
      InvoiceData: {},
    };
  },
  computed: {
    ...mapGetters(["getNavbarData"]),
    getUserType() {
      return this.getNavbarData.userType;
    },

    formattedLimit() {
      if (!this.Limitations) {
        return {};
      }

      return this.Limitations.reduce((result, limit) => {
        result[limit.text] = {
          userValue: limit.userValue,
          planValue: limit.planValue,
        };
        return result;
      }, {});
    },
    ...mapGetters([
      "GetTokenFromMetaTag",
      "getNavbarData",
      "getIsAccountDataLoading",
      "getUserAllAccountData",
    ]),
    userAllAccountData() {
      return this.getUserAllAccountData;
    },
    Limitations() {
      return this.userAllAccountData?.limitations || [];
    },
    plan() {
      return this.userAllAccountData?.plan || {};
    },
    user() {
      return this.userAllAccountData?.account || {};
    },
  },
  mounted() {
    this.getInvoiceDetail();
  },
  methods: {
    openUpgradePlan() {
      let webUsers = ["web", "bc", "wordpress"];
      if (webUsers.includes(this.getUserType)) {
        let url = window.location.origin + `/manage/account/pricing`;
        window.open(url, "_self");
      }
      if (this.getUserType == "shopify") {
        // const envPath = process.env.VUE_APP_SHOPIFY_UPGRADE_PLAN_PATH;
        // const url = `https://${this.getNavbarData.name}${envPath}manage/subscription/plans`;
        // window.open(url, "_parent");
        const token = localStorage.getItem('user_access_token')
        
        if(token){
          window.open(`https://api.quizell.com/manage/subscription/upgrade/plans?token=${token}`,"_blank")
        }
      }
      if (this.getUserType == "wix") {
        let url = `https://www.wix.com/apps/upgrade/f67f3bd5-3d73-486c-9bb2-618adcc19fb9`;
        window.open(url, "_blank");
      }
    },
    ImageSrc(img) {
      if (img == "uploads/profile/1841662374867.jpg") {
        return "https://cdn.shopify.com/s/files/1/0554/9676/9614/files/default.png?v=1663245344";
      } else {
        return img.startsWith("upload") ? `/${img}` : img;
      }
    },

    async getInvoiceDetail() {
      try {
        const response = await axios.get(`/paddle/subscriber/detail`);

        if (response.status == 200 && response.data.status == "success") {
          this.InvoiceData = response.data.data;
        }
      } catch (error) {
        if (error) {
          this.$toasted.show("Error occured", {
            theme: "toasted-primary",
            position: "bottom-center",
            duration: 2000,
          });
        }
        throw error;
      }
    },
  },
};
</script>

<style scoped>
.ai-limitation {
  display: flex;
  padding: 4px var(--Elements-30-33px, 8px);
  justify-content: center;
  align-items: center;
  gap: 4px;
  color: #ffa201;
  background: #ffa20126;
}
.plan-card-header {
  background-color: #4d1b7e0d;
  color: var(--primary-color);
  border-bottom: 1px solid var(--border-color);
  font-family: Poppins;
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: 24px; /* 150% */
}
.price-text {
  color: #1f1f28;
  font-family: Poppins;
  font-size: 30px;
  font-style: normal;
  font-weight: 600;
  line-height: 38px; /* 126.667% */
}
</style>
